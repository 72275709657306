import {
  Scene,
  Engine,
  Vector3,
  CubeTexture,
  SceneLoader,
  AbstractMesh,
  ArcRotateCamera,
  MeshBuilder,
  Color4
} from "@babylonjs/core";
import "@babylonjs/loaders";

  export class CameraMechanics {
      scene: Scene;
      engine: Engine;
      Mesh: AbstractMesh;
      camera: ArcRotateCamera;
      meshName  = 'C5';
      url: 'https://ky3d.com/addons/yb_guanwang/core/public/upload/10/944f6ca0871ed91932be377292009905.glb';
      
      constructor(canvas: HTMLCanvasElement,tem_id,murl) {
        this.tem_id = tem_id;
        this.murl=murl
          this.engine = new Engine(canvas, true);
          this.scene = this.CreateScene();
          // this.meshName = meshName
          this.CreateCamera()
          this.CreateWatch();
          this.engine.runRenderLoop(() => {
          this.scene.render();
          });
      }

CreateScene(): Scene {
  const scene = new Scene(this.engine);
  console.log("./template/"+this.tem_id+"/"+this.tem_id+".env")
  const envTex = CubeTexture.CreateFromPrefilteredData(
    "./template/"+this.tem_id+"/"+this.tem_id+".env",
    scene
  );
  scene.createDefaultLight()
  envTex.gammaSpace = false;
  envTex.rotationY = Math.PI;
  scene.environmentTexture = envTex;
  scene.environmentIntensity = 0.8;
  scene.clearColor = new Color4(0,0,0,0);
  return scene;
}

CreateCamera(): void {
  this.camera = new ArcRotateCamera(
    "camera",
    -Math.PI/2,
    Math.PI/2,
    12,
    new Vector3(0,1,0),
    this.scene
  );

  this.camera.attachControl(this.canvas, true);
  this.camera.wheelPrecision = 20;//滚轮灵敏度
  this.camera.minZ = 0.3;//近裁剪

  this.camera.lowerRadiusLimit = 10;//摄像机最近距离
  this.camera.upperRadiusLimit = 12;//最远距离

  this.camera.panningSensibility = 0;//摄像机无法平移

  // this.camera.useBouncingBehavior = true;//自动弹回

  this.camera.useAutoRotationBehavior = true;
  this.camera.autoRotationBehavior.idleRotationSpeed = 0.2;//旋转速度
  this.camera.autoRotationBehavior.idleRotationSpinupTime = 4000;//静止至旋转所需时间
  this.camera.autoRotationBehavior.idleRotationWaitTime = 3000;//暂停至重新开始等待时间
  this.camera.autoRotationBehavior.zoomStopsAnimation = true;//放大暂停自动旋转

  this.camera.useFramingBehavior = true;

  // this.camera.framingBehavior.radiusScale = 0.2;

  this.camera.framingBehavior.framingTime = 1500;
}

async CreateWatch(): Promise<void> {
  // let glbFilePath = "./template/" + getQueryVariable("tem_id") + "/model/" ;
  // let glbFilePath = "./template/" + this.tem_id + "/model/" ;
  let glbFileName = this.murl 
  // console.log(this.meshName)
  const { meshes } = await SceneLoader.ImportMeshAsync(
     '',
     '',
     glbFileName
  );
  // const cube = MeshBuilder.CreateBox('name',{size:1});
  // cube.position =Vector3.Zero();

  // console.log("meshes", meshes);
    // mesh.position = Vector3.Zero();
  // meshes.map(mesh=>{if(mesh.name.indexOf(this.meshName) !==-1) {
  //   // this.Mesh=mesh;
  //   this.camera.setTarget(mesh)
  //   mesh.position = Vector3.Zero();
  // }})
  // console.log(this.Mesh);
  // this.Mesh.position =Vector3.Zero();
  this.camera.setTarget(meshes[1]);

  // this.Mesh = meshes[0];

  // meshes[1].showBoundingBox = true;
  // meshes[2].showBoundingBox = true;
  // meshes[3].showBoundingBox = true;

  // this.camera.setTarget(meshes[2]);

  this.engine.hideLoadingUI();
}
}