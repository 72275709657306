<template>
    <div>
        <canvas id="canvas1" :width="vwidth" :height="vheight" style="width: 100%;height: 100%;"></canvas>
    </div>
</template>
<script>
import { CameraMechanics } from "@/js/CameraMechanics";
import { getQueryVariable } from "@/js/tool";

export default {
  name: "ViewModel",
  data(){
    return {
      vheight: document.documentElement.clientHeight,
      vwidth: document.documentElement.clientWidth
    }
  },
  created(){

  },
  mounted() {
    
    new CameraMechanics(document.getElementById("canvas1"),getQueryVariable("tem_id"),getQueryVariable('murl'));
  },
  methods:{
    viewModelOpen(){
      const canvas = document.getElementById("canvas1");
      new CameraMechanics(canvas,'');
    }
  }
};
</script>
<style>
body{
    margin: 0;
}
#canvas1{
    width: 100%;
    height: 100%;
}
</style>